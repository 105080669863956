
// @group components

export default {
  name: "HeroComponent",
  props: ["data"],
  methods: {
    scrollDown() {
      window.scrollTo({ top: innerHeight / 1.1, behavior: "smooth" });
    },
  },
};
