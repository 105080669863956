import { render, staticRenderFns } from "./Hero.vue?vue&type=template&id=69271078&scoped=true"
import script from "./Hero.vue?vue&type=script&lang=js"
export * from "./Hero.vue?vue&type=script&lang=js"
import style0 from "./Hero.vue?vue&type=style&index=0&id=69271078&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69271078",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Medias: require('/vercel/path0/components/elements/Medias/index.vue').default,BookingButton: require('/vercel/path0/components/elements/BookingButton.vue').default,Section: require('/vercel/path0/components/elements/Section.vue').default})
